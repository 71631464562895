export default class InstrumentPositionForCreationModel {
    name: string;
    location: string;
    start_year: number;
    end_year: number;
    instrument_id: number;

    constructor(instrument_id: number) {
        this.instrument_id = instrument_id;
    }
}