import InstrumentPositionForCreationModel from "./InstrumentPositionForCreationModel";

export default class InstrumentPositionForUpdateModel extends InstrumentPositionForCreationModel {
    id: number;

    constructor(obj?: InstrumentPositionForUpdateModel) {
        super(obj.instrument_id);
        this.id = obj?.id;
        this.name = obj?.name;
        this.location = obj?.location;
        this.start_year = obj?.start_year;
        this.end_year = obj?.end_year;
    }
}