import { all, call, takeEvery, put, select } from "redux-saga/effects";
import get from "lodash/get";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { getDefaultPeopleForCreation, PeopleForCreationModel } from "../../../../models/administration/core/people/PeopleForCreationModel";
import { putNewForm } from "../../../forms";
import { handleFormErrors, handleRequestErrors } from "../../../../util/errorHandling";

import * as constants from "../../../../constants/administration/core/people";
import * as actions from "../../../../actions/administration/core/people";
import * as tableActions from "../../../../actions/tables";
import * as gridActions from "../../../../actions/photoGallery";
import * as videosActions from "../../../../actions/videoGallery";
import * as documentActions from "../../../../actions/documentViewer";
import { ActionWithCallback } from "../../../../actions/actionWithCallback";
import { AppState } from "../../../../store/appState";

import ajax from "../../../../util/api";
import { push } from "connected-react-router";
import PeopleForUpdateModel from "../../../../models/administration/core/people/PeopleForUpdateModel";
import { PeoplePhotoForCreateModel, PeoplePhotoForUpdateModel } from "../../../../models/administration/core/people/PeoplePhotoForSaveModel";
import { PeopleVideoForCreateModel, PeopleVideoForUpdateModel } from "../../../../models/administration/core/people/PeopleVideoForSaveModel";
import PeopleHomepageModel from "../../../../models/administration/core/people/PeopleHomepageModel";

function* initAdd(action: ActionWithDataPathBase<null>) {
    yield call(putNewForm, action.dataPath, getDefaultPeopleForCreation);
}

function* create(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: PeopleForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        const { data } = yield call(ajax.post, "/people", formData);
        yield all([
            put(actions.changeValue("add.isOpen", false)),
            put(push(`/administration/people/stories/edit/${data.id}`))
        ]);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("add.isLoading", false));
    }
}

function* initEdit(action: ActionWithDataPathBase<number>) {
    try {
        const { data } = yield call(ajax.get, `/people/${action.payload}?append=avatar_url,story_url&include=photos,videos,document`);
        yield call(putNewForm, action.dataPath, new PeopleForUpdateModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("edit.errors", message));
    }
}

function* deleteAvatar(action: ActionWithDataPathBase<number>) {
    try {
        yield call(ajax.post, `/people/${action.payload}/delete-avatar`);
        yield call(initEdit, {
            ...action
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("edit.errors", message));
    }
}

function* deleteStory(action: ActionWithDataPathBase<number>) {
    try {
        yield call(ajax.post, `/people/${action.payload}/delete-story`);
        yield call(initEdit, {
            ...action
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("edit.errors", message));
    }
}

function* update(action: ActionWithDataPathBase<ActionWithCallback<number>>) {
    try {
        const formData: PeopleForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        if (formData.avatar) {
            formToSend.append("avatar", formData.avatar);
        }
        if (formData.story_photo) {
            formToSend.append("story_photo", formData.story_photo);
        }
        formToSend.append("last_name", formData.last_name);
        formToSend.append("first_name", formData.first_name);
        formToSend.append("date_of_birth", formData.date_of_birth.toUTCString());
        formToSend.append("is_published", formData.is_published.toString());
        formToSend.append("story", formData.story);
        formToSend.append("imprisoned_at", formData.imprisoned_at.toString());
        formToSend.append("imprisonments", formData.imprisonments);
        formToSend.append("places", formData.places);
        formToSend.append("show_in_slider", formData.show_in_slider.toString());
        formToSend.append("_method", "put");

        yield call(ajax.post, `/people/${action.payload.data}`, formToSend);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
        yield put({
            type: constants.INIT_EDIT,
            dataPath: action.dataPath,
            payload: action.payload.data
        });
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("edit.isLoading", false));
    }
}

function* deletePerson(action: ActionWithDataPathBase<null>) {
    try {
        const { id } = yield select((state: AppState) => state.administration.core.people.delete);
        yield call(ajax.delete, `/people/${id}`);
        yield call(closeDialogAndRefreshGrid, "delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "delete", message);
    }
    finally {
        yield put(actions.changeValue("delete.isLoading", false));
    }
}

function* closeDialogAndRefreshGrid(dataPath: string, tableDataPath: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(tableDataPath, {
            url: "/people"
        }))
    ]);
}

function* initPhotoAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new PeoplePhotoForCreateModel(action.payload));
}

function* createPhoto(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PeoplePhotoForCreateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("picture", formData.picture);
        formToSend.append("order_no", formData.order_no.toString());
        if (formData.description) {
            formToSend.append("description", formData.description);
        }
        if (formData.subtitle) {
            formToSend.append("subtitle", formData.subtitle);
        }
        if (formData.show_in_header) {
            formToSend.append("show_in_header", formData.show_in_header.toString());
        } else {
            formToSend.append("show_in_header", "false");
        }

        yield call(ajax.post, `/people/${action.payload.data.id}/photos`, formToSend);
        yield call(closePhotosDialogAndRefreshGrid, "photos.add.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("photos.add.isLoading", false));
    }
}

function* initPhotoEdit(action: ActionWithDataPathBase<{
    people_id: number;
    id: number
}>) {
    try {
        const { data } = yield call(ajax.get, `/people/${action.payload.people_id}/photos/${action.payload.id}`);
        yield call(putNewForm, action.dataPath, new PeoplePhotoForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "photos.edit", message);
    }
}

function* updatePhoto(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    people_id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PeoplePhotoForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("picture", formData.picture);
        formToSend.append("order_no", formData.order_no.toString());
        if (formData.description) {
            formToSend.append("description", formData.description);
        }
        if (formData.subtitle) {
            formToSend.append("subtitle", formData.subtitle);
        }
        formToSend.append("show_in_header", formData.show_in_header.toString());
        formToSend.append("_method", "PUT");

        yield call(ajax.post, `/people/${action.payload.data.people_id}/photos/${action.payload.data.id}`, formToSend);
        yield call(closePhotosDialogAndRefreshGrid, "photos.edit.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("photos.edit.isLoading", false));
    }
}

function* deletePhoto(action: ActionWithDataPathBase<null>) {
    try {
        const { id, people_id } = yield select((state: AppState) => state.administration.core.people.photos.delete);
        yield call(ajax.delete, `/people/${people_id}/photos/${id}`);
        yield call(closePhotosDialogAndRefreshGrid, "photos.delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "photos.delete", message);
    }
    finally {
        yield put(actions.changeValue("photos.delete.isLoading", false));
    }
}

function* closePhotosDialogAndRefreshGrid(dataPath: string, payload: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(gridActions.getData(payload))
    ]);
}

function* initVideoAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new PeopleVideoForCreateModel(action.payload));
}

function* createVideo(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PeopleVideoForCreateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.post, `/people/${action.payload.data.id}/videos`, formData);
        yield call(closeVideosDialogAndRefreshGrid, "videos.add.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("videos.add.isLoading", false));
    }
}

function* initVideoEdit(action: ActionWithDataPathBase<{
    people_id: number;
    id: number
}>) {
    try {
        const { data } = yield call(ajax.get, `/people/${action.payload.people_id}/videos/${action.payload.id}`);
        yield call(putNewForm, action.dataPath, new PeopleVideoForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "video.edit", message);
    }
}

function* updateVideo(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    people_id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PeopleVideoForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.put, `/people/${action.payload.data.people_id}/videos/${action.payload.data.id}`, formData);
        yield call(closeVideosDialogAndRefreshGrid, "videos.edit.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("videos.edit.isLoading", false));
    }
}

function* deleteVideo(action: ActionWithDataPathBase<null>) {
    try {
        const { id, people_id } = yield select((state: AppState) => state.administration.core.people.videos.delete);
        yield call(ajax.delete, `/people/${people_id}/videos/${id}`);
        yield call(closeVideosDialogAndRefreshGrid, "videos.delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "videos.delete", message);
    }
    finally {
        yield put(actions.changeValue("videos.delete.isLoading", false));
    }
}

function* closeVideosDialogAndRefreshGrid(dataPath: string, payload: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(videosActions.getData(payload))
    ]);
}

function* uploadDocument(action: ActionWithDataPathBase<{
    id: number;
    document: File;
}>) {
    try {
        const formToSend = new FormData();
        formToSend.append("document", action.payload.document);

        yield call(ajax.post, `/people/${action.payload.id}/document`, formToSend);
        yield put(documentActions.getData(action.dataPath));
    }
    catch (error) {
        if (error.response) {
            yield put(actions.changeValue("document.errors", "something went wrong"));
        } else {
            yield put(actions.changeValue("document.errors", "something went wrong"));
        }
    }
    finally {
        yield put(actions.changeValue("document.isLoading", false));
    }
}

function* deleteDocument(action: ActionWithDataPathBase<number>) {
    try {
        yield call(ajax.delete, `/people/${action.payload}/document`);
        yield put(documentActions.getData(action.dataPath));
    }
    catch (error) {
        if (error.response) {
            yield put(actions.changeValue("document.errors", "something went wrong"));
        } else {
            yield put(actions.changeValue("document.errors", "something went wrong"));
        }
    }
    finally {
        yield put(actions.changeValue("document.isLoading", false));
    }
}

function* setDialogErrors(basePath: string, message: string) {
    yield all([
        put(actions.changeValue(`${basePath}.errors`, message)),
        put(actions.changeValue(`${basePath}.isOpen`, false))
    ]);
}

function* getHomepage(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/people-homepage");
        yield call(putNewForm, action.dataPath, new PeopleHomepageModel(data));
        yield put(actions.changeValue("homepage.gettingData", false));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("homepage.errors", message));
    }
}

function* updateHomepage(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: PeopleHomepageModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.post, `/people-homepage`, formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("homepage.isLoading", false));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_ADD, initAdd),
        takeEvery(constants.ADD_SUBMIT, create),
        takeEvery(constants.INIT_EDIT, initEdit),
        takeEvery(constants.EDIT_SUBMIT, update),
        takeEvery(constants.DELETE_SUBMIT, deletePerson),
        takeEvery(constants.DELETE_AVATAR, deleteAvatar),
        takeEvery(constants.DELETE_STORY, deleteStory),
        takeEvery(constants.INIT_PHOTO_ADD, initPhotoAdd),
        takeEvery(constants.ADD_PHOTO_SUBMIT, createPhoto),
        takeEvery(constants.INIT_PHOTO_EDIT, initPhotoEdit),
        takeEvery(constants.EDIT_PHOTO_SUBMIT, updatePhoto),
        takeEvery(constants.DELETE_PHOTO_SUBMIT, deletePhoto),
        takeEvery(constants.INIT_VIDEO_ADD, initVideoAdd),
        takeEvery(constants.ADD_VIDEO_SUBMIT, createVideo),
        takeEvery(constants.INIT_VIDEO_EDIT, initVideoEdit),
        takeEvery(constants.EDIT_VIDEO_SUBMIT, updateVideo),
        takeEvery(constants.DELETE_VIDEO_SUBMIT, deleteVideo),
        takeEvery(constants.UPLOAD_DOCUMENT, uploadDocument),
        takeEvery(constants.DELETE_DOCUMENT, deleteDocument),
        takeEvery(constants.GET_HOMEPAGE, getHomepage),
        takeEvery(constants.SAVE_HOMEPAGE, updateHomepage)
    ]);
}