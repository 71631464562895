import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ActionWithPayloadBase } from "../../../actions/actionWithPayloadBase";
import ajax from "../../../util/api";
import * as constants from "../../../constants/dictionaries/prisons";
import map from "lodash/map";
import { prisonsDefaultState } from "../../../reducers/dictionaries/prisons";
import DictionaryPrisonModel from "../../../models/dictionaries/DictionaryPrisonModel";

function* getItems(action: ActionWithPayloadBase<string>) {
    try {
        const { data } = yield call(ajax.get, `/places?include=management&append=prisoners&sort=name&filter[startsWith]=${action.payload}`);
        yield all([
            put({
                type: constants.CHANGE_VALUE,
                dataPath: "items",
                payload: map(data.data, (el: any) => new DictionaryPrisonModel(el))
            }),
            put({
                type: constants.CHANGE_VALUE,
                dataPath: "isOpen",
                payload: true
            })
        ]);
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "",
            payload: prisonsDefaultState
        })
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

function* search(action: ActionWithPayloadBase<string>) {
    try {
        const { data } = yield call(ajax.get, `/places?include=management&sort=name&filter[search]=${action.payload}`);
        yield all([
            put({
                type: constants.CHANGE_VALUE,
                dataPath: "items",
                payload: map(data.data, (el: any) => new DictionaryPrisonModel(el))
            }),
            put({
                type: constants.CHANGE_VALUE,
                dataPath: "isOpen",
                payload: true
            })
        ]);
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "",
            payload: prisonsDefaultState
        })
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_ITEMS, getItems),
        takeEvery(constants.SEARCH, search)
    ]);
}