import PeopleDocument from "../../../repression/People/PeopleDocument";
import { PeoplePhotoForUpdateModel } from "./PeoplePhotoForSaveModel";
import { PeopleVideoForUpdateModel } from "./PeopleVideoForSaveModel";

export default class PeopleForUpdateModel {
    id: number;
    last_name: string;
    first_name: string;
    date_of_birth: Date;
    is_published: boolean;
    story: string;
    avatar: File;
    avatar_url: string;
    story_photo: File;
    story_url: string;
    photos: Array<PeoplePhotoForUpdateModel>;
    videos: Array<PeopleVideoForUpdateModel>;
    document: PeopleDocument;
    imprisoned_at: number;
    imprisonments: string;
    places: string;
    show_in_slider: boolean;

    constructor(obj?: PeopleForUpdateModel) {
        this.id = obj?.id;
        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.date_of_birth = obj ? new Date(obj.date_of_birth) : null;
        this.is_published = obj?.is_published ?? false;
        this.story = obj?.story ?? "<p></p>";
        this.avatar = obj?.avatar;
        this.avatar_url = obj?.avatar_url;
        this.photos = obj?.photos;
        this.videos = obj?.videos;
        this.document = obj?.document;
        this.story_photo = obj?.story_photo;
        this.story_url = obj?.story_url;
        this.imprisoned_at = obj?.imprisoned_at;
        this.imprisonments = obj?.imprisonments;
        this.places = obj?.places;
        this.show_in_slider = obj?.show_in_slider;
    }
}